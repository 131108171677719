<template>
  <div class="accordion-cnt">
    <div
      class="acc-item px-5"
      :class="{ negative: item.isOpen }"
      v-for="(item, index) in accordion"
      :key="index"
      @click="toggleAcc(item)"
    >
      <h1 :class="{ negative: item.isOpen }">
        {{ item.titolo }}
      </h1>

      <transition name="slide-fade">
        <div class="accord-desc" v-if="item.isOpen">
          <p :class="{ negative: item.isOpen }">{{ item.desc }}</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",

  data() {
    return {
      accordion: [
        {
          titolo: "COWORKING",
          desc: "Manage your coworking space's admissions. You can sell the different types of subscriptions and associate them, in one ticket, with your internal services.",
          isOpen: false,
        },
        {
          titolo: "THEATER",
          desc: "If you run a theater or live performance venue, you can sell tickets online on your website. You can also create different ticket categories, such as VIP, general admission, or student pricing.",
          isOpen: false,
        },
        {
          titolo: "MUSEUM",
          desc: "For museums and tourist attractions, our platform provides a simple and efficient way to sell admission tickets, audio guides, and other add-ons. You can even track visitor data and analyze attendance trends.",
          isOpen: false,
        },
        {
          titolo: "SPORT",
          desc: "As a sports organization, you can sell season or individual game tickets and offer different pricing options. You can even use the platform to track attendance and issue refunds if necessary.",
          isOpen: false,
        },
        {
          titolo: "COMMUNITIES",
          desc: "You want to organize an internal event in your community? Maybe free of charge? Don't worry, Mego is the solution!",
          isOpen: false,
        },
        {
          titolo: "DONATIONS",
          desc: "Would you like to raise funds for your project? Look no further, because now you have the opportunity to utilize MEGO—an all-inclusive platform designed also for receiving donations.",
          isOpen: false,
        },
      ],
    };
  },

  methods: {
    toggleAcc(item) {
      this.accordion.forEach((accItem) => {
        if (accItem !== item) {
          accItem.isOpen = false;
        }
      });
      item.isOpen = !item.isOpen;
    },
  },
};
</script>

<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'

/* STYLESHEETS */
import './themes/global.scss'
import './themes/resp.scss'
import './themes/theme-colors.scss'
import './fonts/stylesheet.css'

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import MarqueeText from 'vue-marquee-text-component'
Vue.component('marquee-text', MarqueeText);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <svg
    width="16"
    height="25"
    viewBox="0 0 16 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.81204 0V9.24151L15.623 12.7318L7.81204 0Z"
      fill="#C377D3"
      fill-opacity="0.602"
    />
    <path d="M7.81204 0L0 12.7318L7.81204 9.24151V0Z" fill="#C377D3" />
    <path
      d="M7.81204 18.7208V25.0002L15.6283 14.1865L7.81204 18.7208Z"
      fill="#C377D3"
      fill-opacity="0.602"
    />
    <path
      d="M7.81204 25.0002V18.7198L0 14.1865L7.81204 25.0002Z"
      fill="#C377D3"
    />
    <path
      d="M7.81204 17.2672L15.623 12.7319L7.81204 9.24365V17.2672Z"
      fill="#C377D3"
      fill-opacity="0.2"
    />
    <path
      d="M0 12.7319L7.81204 17.2672V9.24365L0 12.7319Z"
      fill="#C377D3"
      fill-opacity="0.602"
    />
  </svg>
</template>

<script>
export default {
  props: ["theme"],
};
</script>

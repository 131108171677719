<template>
  <section class="multichain">
    <div class="carousel-cnt">
      <carousel
        :perPage="1"
        :centerMode="true"
        :paginationEnabled="false"
        :navigationEnabled="true"
        navigationNextLabel=""
        navigationPrevLabel=""
      >
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">
            Multichain<br />
            Ticketing system
          </h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <div class="d-flex align-items-center mb-3">
                <Eth class="chain-logo" />
                <Tezos
                  class="chain-logo"
                  :class="{ 'ms-4': !isMobile, 'ms-3': isMobile }"
                />
                <Solana
                  class="chain-logo"
                  :class="{ 'ms-4': !isMobile, 'ms-3': isMobile }"
                />
                <Optimism
                  class="chain-logo"
                  :class="{ 'ms-4': !isMobile, 'ms-3': isMobile }"
                />
                <Arbitrum
                  class="chain-logo"
                  :class="{ 'ms-4': !isMobile, 'ms-3': isMobile }"
                />
                <Quadrans
                  class="chain-logo"
                  :class="{ 'ms-4': !isMobile, 'ms-3': isMobile }"
                />
              </div>
              <p>
                Ethereum, Tezos, Solana, Optimism, Arbitrum, Quadrans: doesn’t
                matter what network you like to use, you can choose the
                blockchain you like to create tickets and users can choose how
                to pay, even in a different coin, also in FIAT.
              </p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">
            Simple wallet<br />
            for everyone
          </h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>
                You don't need to own a wallet, you just need an email and a
                password to use the platform.
              </p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">Multiple benefits</h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>
                You can create more than one benefit. While admission is the
                default, you can easily offer free drinks, swag, etc. with the
                same ticket.
              </p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">
            Improve ticket<br />
            management
          </h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>
                Our platform enables you to track ticket sales, manage
                attendance, and prevent ticket fraud in real-time.
              </p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">Pay as you wish</h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>
                Don’t mind the blockchain used to create tickets, pay in FIAT
                and in crypto using your card or wallet.
              </p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">MEGO Verifier</h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>You can scan event tickets and track your statistics.</p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">
            Easy device setup<br />
            for gates
          </h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>
                You can create several gates to speed-up onboarding of
                attendees.
              </p>
            </div>
          </div>
        </slide>
        <slide class="multi-slide">
          <h1 class="ps-5 text-center">
            Store like<br />
            a flash
          </h1>
          <div class="layers-cnt">
            <div class="layer layer-1"></div>
            <div class="layer layer-2"></div>
            <div class="layer layer-3">
              <p>
                Easily and safely import your tickets in Apple or Google
                Wallets.
              </p>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import checkVieport from "../mixins/checkViewport";

import Eth from "../components/icons/Eth.vue";
import Tezos from "../components/icons/Tezos.vue";
import Solana from "../components/icons/Solana.vue";
import Optimism from "../components/icons/Optimism.vue";
import Arbitrum from "../components/icons/Arbitrum.vue";
import Quadrans from "../components/icons/Quadrans.vue";

export default {
  name: "Multichain",
  mixins: [checkVieport],
  components: {
    Carousel,
    Slide,
    Eth,
    Tezos,
    Solana,
    Optimism,
    Arbitrum,
    Quadrans,
  },
};
</script>

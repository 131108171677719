<template>
  <nav>
    <div class="row p-0 m-0">
      <div class="col-8 col-lg-10 py-3 ps-5 colored-bg">
        <div class="logo-ext-cnt">
          <svg
            width="117"
            height="27"
            viewBox="0 0 117 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="showDesk"
              d="M48.3827 17.1799C48.291 17.4153 47.9525 17.4153 47.8609 17.1799L42.9242 4.66699H39.6002V21.5947H42.0567V10.4019C42.0567 10.0973 42.4846 10.0188 42.5974 10.3027L47.1039 21.5947H49.1444L53.6438 10.3073C53.7566 10.0234 54.1845 10.1042 54.1845 10.4065L54.1821 21.5924H56.6387V4.6693H53.2888L48.3827 17.1799Z"
              fill="white"
            />
            <path
              class="showDesk"
              d="M62.438 13.9494H77.1116C77.1375 13.6932 77.161 13.4117 77.161 13.1324C77.161 8.19605 73.0095 4.23124 67.9341 4.41586C63.3548 4.58433 59.5653 8.30452 59.3984 12.8001C59.2127 17.7804 63.2466 21.8513 68.275 21.8513C71.9328 21.8513 75.0688 19.6843 76.4228 16.5826L73.7406 16.3749C73.4796 16.7949 73.1458 17.1919 72.7885 17.5634C71.5778 18.7519 69.9863 19.4051 68.275 19.4051C66.5636 19.4051 64.9721 18.7519 63.7614 17.5634C62.8776 16.6957 62.2734 15.6087 62.0313 14.4156C61.9842 14.1733 62.1841 13.9494 62.438 13.9494ZM62.2099 11.187C62.2146 11.1708 62.2193 11.1523 62.224 11.1385C62.2405 11.09 62.2593 11.0462 62.2757 10.9977C62.2851 10.9746 62.2922 10.9516 62.3016 10.9308C62.3486 10.8131 62.3956 10.6977 62.4497 10.5823C62.4756 10.52 62.5038 10.4623 62.5367 10.4023C62.5649 10.3377 62.6002 10.2731 62.6331 10.2108C62.6495 10.1738 62.673 10.1346 62.6965 10.0977C62.7389 10.0169 62.7882 9.93152 62.8399 9.85074C62.9786 9.63381 63.1267 9.42149 63.2866 9.22302C63.3477 9.15148 63.4065 9.07994 63.4676 9.0107C63.4888 8.98993 63.5052 8.96916 63.524 8.94839C63.5992 8.863 63.6792 8.78223 63.7591 8.70376C64.9698 7.51524 66.5612 6.86214 68.2726 6.86214C69.984 6.86214 71.5755 7.51524 72.7861 8.70376C72.8661 8.78223 72.946 8.86531 73.0212 8.94839C73.0424 8.96916 73.0588 8.98993 73.0776 9.0107C73.1435 9.08455 73.2093 9.16302 73.2728 9.23917C73.2751 9.24379 73.2822 9.25302 73.2869 9.25994C73.3597 9.35226 73.4303 9.44688 73.4984 9.5415C73.5713 9.64304 73.6395 9.74689 73.7053 9.85074C73.7688 9.95229 73.8275 10.0538 73.884 10.1554C73.9968 10.3585 74.0932 10.5639 74.1825 10.7716C74.1919 10.7923 74.1966 10.8108 74.206 10.8316C74.246 10.9308 74.2836 11.0277 74.3212 11.1293C74.3212 11.1316 74.3236 11.1385 74.3236 11.1408C74.3283 11.157 74.333 11.1708 74.3377 11.1893C74.3659 11.3716 74.2154 11.5493 74.0156 11.5493H62.532C62.3369 11.5493 62.1817 11.3739 62.2076 11.1939C62.2099 11.1893 62.2099 11.187 62.2099 11.187Z"
              fill="white"
            />
            <path
              class="showDesk"
              d="M108.14 21.7864C103.255 21.7864 99.2797 17.8839 99.2797 13.0882C99.2797 8.29263 103.255 4.39014 108.14 4.39014C113.025 4.39014 117 8.29263 117 13.0882C117 17.8839 113.025 21.7864 108.14 21.7864ZM108.14 6.87564C104.651 6.87564 101.811 9.66347 101.811 13.0882C101.811 16.513 104.651 19.3009 108.14 19.3009C111.628 19.3009 114.468 16.513 114.468 13.0882C114.468 9.66347 111.628 6.87564 108.14 6.87564Z"
              fill="white"
            />
            <path
              class="showDesk"
              d="M96.9312 12.9959V13.0906C96.9312 16.4623 94.9659 19.3909 92.1027 20.8355C92.0368 20.8679 91.9734 20.9002 91.9075 20.9302C91.8417 20.9602 91.7782 20.9902 91.7124 21.0179C91.6019 21.0686 91.4891 21.1148 91.3786 21.1586C91.2634 21.2048 91.1459 21.2463 91.026 21.2879C90.8943 21.334 90.758 21.3756 90.624 21.4171C90.4524 21.4679 90.2761 21.5117 90.0974 21.5533C89.9423 21.5902 89.7871 21.6179 89.6296 21.6433C89.6061 21.6479 89.5849 21.6525 89.5614 21.6571C89.3922 21.6848 89.2182 21.7079 89.0443 21.7264C89.0419 21.7264 89.0396 21.7287 89.0349 21.7287C88.7175 21.761 88.3954 21.7864 88.0663 21.7864C83.1814 21.7864 79.2062 17.8839 79.2062 13.0882C79.2062 8.29263 83.1814 4.39014 88.0663 4.39014C91.8582 4.39014 95.0976 6.73717 96.3599 10.0304H93.5719C92.4858 8.14724 90.4242 6.87333 88.064 6.87333C84.5731 6.87333 81.7356 9.65885 81.7356 13.0859C81.7356 16.513 84.5731 19.2985 88.064 19.2985C88.1956 19.2985 88.3273 19.2962 88.4589 19.2847C91.0754 19.1301 93.2639 17.4038 94.0656 15.0591C94.075 15.0406 94.0797 15.0176 94.0891 14.9991C94.0891 14.9968 94.0914 14.9876 94.0914 14.9853C94.0961 14.9714 94.1032 14.9553 94.1055 14.9391C94.1102 14.8976 94.1079 14.8583 94.0961 14.8214C94.0632 14.6899 93.9363 14.5814 93.7835 14.5814H88.4589V12.1813H96.8771C96.8795 12.1997 96.8795 12.2205 96.8818 12.2413C96.8865 12.2851 96.8912 12.3313 96.8936 12.3774C96.903 12.4882 96.91 12.599 96.9171 12.7121C96.9288 12.8044 96.9312 12.9013 96.9312 12.9959Z"
              fill="white"
            />
            <path
              d="M28.5151 0H12.9757C11.5369 0 10.158 0.566034 9.13886 1.55962C6.38292 4.26516 4.35343 6.26619 1.59573 8.96653C0.576578 9.95839 0 11.319 0 12.7314V25.8385C0 26.479 0.532498 27 1.19195 27H15.9591C17.4049 27 18.7838 26.434 19.8029 25.4335C22.8445 22.4475 25.0626 20.2768 28.1113 17.2839C29.1234 16.2834 29.7 14.9298 29.7 13.5173V1.17015C29.7 0.522759 29.1675 0 28.5151 0ZM26.5367 8.85575L20.6775 21.8745C19.7853 23.8686 17.419 24.7653 15.3878 23.8894L3.86502 18.8955C3.17559 18.5943 2.86879 17.8067 3.16854 17.1299L9.02777 4.1111C9.91997 2.11873 12.2862 1.22035 14.3175 2.09623L25.8402 7.09014C26.5297 7.39133 26.8365 8.17893 26.5367 8.85575Z"
              fill="white"
            />
          </svg>
        </div>
        <!--  <div class="btn-theme-color-cnt moon-btn" @click="$emit('toggle-moon')">
          <svg
            width="27"
            height="19"
            viewBox="0 0 27 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="moon-color"
          >
            <ellipse cx="9.5" cy="9.5" rx="9.5" ry="9.5" fill="white" />
            <ellipse
              :class="isActive ? 'moonfade-in' : 'moonfade-out'"
              cx="17.5"
              cy="9.5"
              rx="9.5"
              ry="9.5"
              fill="#151515"
            />
          </svg>
        </div> -->
      </div>
      <div class="col-4 col-lg-2 launch-app-btn py-3 ps-4">
        <a
          href="https://app.mego.tickets/#/
"
          target="_blank"
          >LAUNCH APP</a
        >
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",

  data() {
    return {
      isActive: false,
      userTheme: "dark-theme",
    };
  },
};
</script>



<template>
  <footer class="pt-5">
    <div class="gradient-footer-cnt">
      <img
        class="opacity-svg"
        src="../assets/img/gradient-footer2_comp.svg"
        draggable="false"
      />
    </div>

    <div class="row p-5 pb-3 showDesk row1 mx-0">
      <div class="col-6">
        <svg
          class="mini-logo"
          width="30"
          height="27"
          viewBox="0 0 30 27"
          fill="none"
        >
          <path
            d="M28.5151 0H12.9757C11.5369 0 10.158 0.566034 9.13886 1.55962C6.38292 4.26516 4.35343 6.26619 1.59573 8.96653C0.576578 9.95839 0 11.319 0 12.7314V25.8385C0 26.479 0.532498 27 1.19195 27H15.9591C17.4049 27 18.7838 26.434 19.8029 25.4335C22.8445 22.4475 25.0626 20.2768 28.1113 17.2839C29.1234 16.2834 29.7 14.9298 29.7 13.5173V1.17015C29.7 0.522759 29.1675 0 28.5151 0ZM26.5367 8.85575L20.6775 21.8745C19.7853 23.8686 17.419 24.7653 15.3878 23.8894L3.86502 18.8955C3.17559 18.5943 2.86879 17.8067 3.16854 17.1299L9.02777 4.1111C9.91997 2.11873 12.2862 1.22035 14.3175 2.09623L25.8402 7.09014C26.5297 7.39133 26.8365 8.17893 26.5367 8.85575Z"
            fill="white"
          />
        </svg>
      </div>

      <div class="col-2"><p>Reach us</p></div>
      <div class="col-2"><p>Social</p></div>
      <div class="col-2"><p>Creator</p></div>
    </div>
    <div class="row px-5 showDesk row2 m-0">
      <div class="col-6">
        <h1>NFT tickets<br />made easy!</h1>
      </div>
      <div class="col-2">
        <a href="mailto:info@mego.tickets">
          <span>
            <svg
              width="33"
              height="27"
              viewBox="0 0 33 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.1915 0.999999L31.4978 13.3589C31.5755 13.4369 31.5755 13.5631 31.4978 13.6411L19.1915 26"
                stroke="white"
                stroke-linecap="round"
              />
              <path d="M31 13.5L1 13.5" stroke="white" stroke-linecap="round" />
            </svg>
          </span>
          Send a mail!</a
        >
      </div>
      <div class="col-2 pe-0">
        <a href="https://twitter.com/mego_tickets" target="_blank">Twitter</a>
        <!-- <a href="">Discord</a> -->
        <a href="https://www.linkedin.com/company/mego-tickets/" target="_blank"
          >LinkedIn</a
        >
      </div>
      <div class="col-2 px-0">
        <a href="mailto:info@mego.tickets" target="_blank">Request a demo</a>
        <a :href="`${publicPath}mego_investors.pdf`" download>Investor deck</a>
        <a href="https://join.mego.tickets/#/" target="_blank">Join MEGO</a>
      </div>
    </div>

    <div class="row showMob">
      <div class="col-12 pb-4">
        <svg
          class="mini-logo"
          width="30"
          height="27"
          viewBox="0 0 30 27"
          fill="none"
        >
          <path
            d="M28.5151 0H12.9757C11.5369 0 10.158 0.566034 9.13886 1.55962C6.38292 4.26516 4.35343 6.26619 1.59573 8.96653C0.576578 9.95839 0 11.319 0 12.7314V25.8385C0 26.479 0.532498 27 1.19195 27H15.9591C17.4049 27 18.7838 26.434 19.8029 25.4335C22.8445 22.4475 25.0626 20.2768 28.1113 17.2839C29.1234 16.2834 29.7 14.9298 29.7 13.5173V1.17015C29.7 0.522759 29.1675 0 28.5151 0ZM26.5367 8.85575L20.6775 21.8745C19.7853 23.8686 17.419 24.7653 15.3878 23.8894L3.86502 18.8955C3.17559 18.5943 2.86879 17.8067 3.16854 17.1299L9.02777 4.1111C9.91997 2.11873 12.2862 1.22035 14.3175 2.09623L25.8402 7.09014C26.5297 7.39133 26.8365 8.17893 26.5367 8.85575Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="col-12 pb-5">
        <h1>NFT Tickets<br />made easy!</h1>
      </div>
      <div class="col-12 pb-5">
        <p class="pb-2">Reach us</p>
        <a href="mailto:info@mego.tickets"> Send a mail!</a>
      </div>
    </div>
    <div class="row links-row pb-5 showMob">
      <div class="col-6">
        <p class="pb-2">Creator</p>
        <a href="mailto:info@mego.tickets" target="_blank">Request a demo</a>
        <a :href="`${publicPath}mego_investors.pdf`" download>Investor deck</a>
        <a href="https://join.mego.tickets/#/" target="_blank">Join MEGO</a>
      </div>
      <div class="col-6">
        <p class="pb-2">Social</p>
        <a href="https://twitter.com/mego_tickets" target="_blank">Twitter</a>
        <!-- <a href="">Discord</a> -->
        <a href="https://www.linkedin.com/company/mego-tickets/" target="_blank"
          >LinkedIn</a>
      </div>
    </div>
    <div class="row px-5 copy mx-0">
      <p>
        Built w/ love by
        <a href="https://yomi.digital/" target="_blank">Yomi</a>
      </p>
    </div>
    <div class="privacy">
      <a href="https://www.iubenda.com/terms-and-conditions/84994680" target="_blank">Terms and Conditions</a>
      <a href="https://www.iubenda.com/privacy-policy/84994680 " target="_blank">Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/84994680/cookie-policy" target="_blank">Cookie Policy</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  data() {
    const publicPath = process.env.BASE_URL;
    /* console.log(publicPath); */
    return {
      publicPath,
    };
  },

  methods: {},
};
</script>
<style>
footer {
  position: relative;
}

.footer-blob-cnt {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  left: 0;
  z-index: -2;
  height: 100%;
  max-height: 100%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  bottom: 0;
  justify-content: flex-end;
}

.footer-blob-grad {
  max-height: 100%;
  width: 100vw;
  height: auto;
}

.gradient-footer-cnt {
  width: 100vw;
  max-width: 100vw;
  position: absolute;
  z-index: -2;
  bottom: 0;
  left: -3rem;
  height: 100%;
}
.gradient-footer-cnt img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-top: -20%;
}
</style>

<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="12.5" fill="#C377D3" />
    <path
      d="M7.84235 16.574C7.00702 16.574 6.32307 16.3778 5.79048 15.9853C5.26349 15.5873 5 15.0155 5 14.281C5 14.1241 5.01682 13.9391 5.05046 13.7148C5.14016 13.2103 5.2691 12.6048 5.43728 11.8928C5.91381 9.96427 7.14718 9 9.13177 9C9.66997 9 10.1577 9.0897 10.5838 9.2747C11.0099 9.4485 11.3462 9.71759 11.5929 10.0764C11.8396 10.4296 11.9629 10.85 11.9629 11.3378C11.9629 11.4835 11.9461 11.6686 11.9125 11.8928C11.8059 12.5151 11.6826 13.1262 11.5312 13.7148C11.2846 14.6735 10.8641 15.3967 10.2586 15.8732C9.65876 16.3441 8.85146 16.574 7.84235 16.574ZM7.99371 15.0603C8.38615 15.0603 8.71692 14.9426 8.99162 14.7127C9.27193 14.4829 9.47375 14.1297 9.59148 13.6475C9.75406 12.986 9.8774 12.4142 9.96149 11.9208C9.98952 11.7751 10.0063 11.6237 10.0063 11.4667C10.0063 10.8276 9.67558 10.5081 9.00844 10.5081C8.616 10.5081 8.27963 10.6258 7.99932 10.8557C7.72462 11.0855 7.5284 11.4387 7.41067 11.9208C7.28173 12.3918 7.15839 12.9636 7.02945 13.6475C7.00142 13.7877 6.9846 13.9335 6.9846 14.0904C6.97899 14.7408 7.32097 15.0603 7.99371 15.0603Z"
      fill="white"
    />
    <path
      d="M12.4507 16.473C12.3722 16.473 12.3161 16.4506 12.2713 16.4001C12.2376 16.3441 12.2264 16.2824 12.2376 16.2095L13.6896 9.36994C13.7009 9.29145 13.7401 9.22978 13.8074 9.17933C13.869 9.12887 13.9363 9.10645 14.0092 9.10645H16.8067C17.586 9.10645 18.2082 9.26903 18.6792 9.58858C19.1557 9.91374 19.3968 10.3791 19.3968 10.9901C19.3968 11.1639 19.3743 11.3489 19.3351 11.5395C19.1613 12.3468 18.8081 12.9411 18.2699 13.3279C17.7429 13.7147 17.0197 13.9054 16.1003 13.9054H14.6819L14.1998 16.2095C14.183 16.288 14.1494 16.3497 14.0821 16.4001C14.0204 16.4506 13.9531 16.473 13.8803 16.473H12.4507ZM16.1732 12.4534C16.4703 12.4534 16.7226 12.3749 16.9412 12.2123C17.1655 12.0497 17.3113 11.8198 17.3841 11.5171C17.4066 11.3994 17.4178 11.2929 17.4178 11.2032C17.4178 11.0013 17.3561 10.8444 17.2384 10.7379C17.1206 10.6257 16.9132 10.5697 16.6273 10.5697H15.3659L14.9679 12.4534H16.1732Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: ["theme"],
};
</script>
